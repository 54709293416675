import React, { Component } from "react";
import { observer } from "mobx-react";
import Store from "../../../Store/Store";
export default
@observer
class Invalid extends Component {
  render() {
    return (
      <div>
        <div className="row d-flex justify-content-center">
          <div className="col-12 col-md-9 text-center my-4">
            <img id="logo" src="/img/liv-logo.png" />
          </div>
          {/* Logo */}
        </div>
        <div id="game-header" className="row justify-content-center">
          <div className="col-12 col-md-9 text-center">
            <p>{Store.lang.txt["invalid"]}</p>
          </div>
        </div>
      </div>
    );
  }
}
