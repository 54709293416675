import { observable, action, runInAction, toJS, when } from "mobx";
import LangState from "./LangState/LangState";
import CardState from "./CardState/CardState";
import RouterState from "./RouterState/RouterState";
import { configure } from "mobx";
import Api from "../Api/Api";
/**
 * the App State Root Object
 */
export class State {
  @observable lang = new LangState(this);
  @observable cards = new CardState(this);
  @observable router = new RouterState(this);
  @observable initialized: boolean = false;
  @observable loading = true;
  @observable alertTitle = "";
  @observable alertMessage = "";
  @observable alertVisible = false;
  @observable alertButtonLabel = "";
  @observable confirmTitle = "";
  @observable confirmMessage = "";
  @observable confirmation = null;
  @observable confirmVisible = false;
  @observable confirmButtonLabel = "";
  @observable confirmCancelLabel = "";
  @observable loginError = false;
  @observable loggingIn = false;
  @observable loggedIn = false;
  @observable password;

  @action async init() {
    if (this.initialized) {
      return true;
    }
    let response = await Api.getSession();
    if (response.success) {
      if (response.session.loggedIn) {
        runInAction(() => {
          this.loggedIn = true;
        });
      }
    }
    await this.router.init();
    await this.lang.init("en");
    await this.cards.init();
    action(() => {
      this.loading = false;
      this.initialized = true;
    })();
  }

  @action async alert(title, message, alertButtonLabel = "ok") {
    this.alertTitle = title;
    this.alertMessage = message;
    this.alertButtonLabel = alertButtonLabel;
    this.alertVisible = true;
    return new Promise((resolve, reject) => {
      when(
        () => !this.alertVisible,
        () => {
          resolve(true);
        }
      );
    });
  }

  @action closeAlert() {
    this.alertTitle = "";
    this.alertMessage = "";
    this.alertVisible = false;
  }

  @action async confirm(
    title,
    message,
    confirmLabel = "confirm",
    cancelLabel = "cancel"
  ) {
    this.confirmTitle = title;
    this.confirmMessage = message;
    this.confirmButtonLabel = confirmLabel;
    this.confirmCancelLabel = cancelLabel;
    this.confirmVisible = true;
    return new Promise((resolve, reject) => {
      when(
        () => this.confirmation !== null,
        () => {
          resolve(this.confirmation);
          this.resetConfirm();
        }
      );
    });
  }
  @action resetConfirm() {
    this.confirmVisible = false;
    this.confirmMessage = "";
    this.confirmTitle = "";
    this.confirmation = null;
  }

  @action setConfirmation(value: boolean) {
    this.confirmation = value;
  }

  @action async login(password) {
    let response = await Api.login(password);
    runInAction(() => {
      if (response.success) {
        this.loggedIn = true;
      }
    });
  }

  @action async logout() {
    await Api.logout();
    runInAction(() => {
      this.loggedIn = false;
    });
  }

  @action async setAdminLang(lang) {
    await Api.setLang(lang);
  }
}
configure({
  enforceActions: "always"
});
const state = new State();
state.init().then(() => {});
window.store = state;
export default state;
