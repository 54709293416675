import React from "react";
import "./App.css";
import { ThemeProvider } from "react-css-themr";
import theme from "../theme";
import Admin from "./pages/Admin";
import Card from "./pages/Card/Card";
import Route from "./Route/Route";
import Store from "../Store/Store";
import Thanks from "./pages/Card/Thanks";
import { observer } from "mobx-react";
import Loading from "./pages/Loading";
import Invalid from "./pages/Card/Invalid";
import Login from "./pages/Login";

/**
 * App
 *
 * App controls the content via the Store.router.
 * In the case of the Admin, it will show a login form if the user is not logged in
 *
 * In the case of Card, it will show one of three components
 * - Invalid if the key does not correspond to a valid card key
 * - Card if the card has not been played
 * _ Thanks if the card has already been played
 */
@observer
class App extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        {Store.loading ? (
          <Loading />
        ) : (
          <div className={"container"}>
            <Route section={"admin"}>
              {Store.loggedIn ? <Admin /> : <Login />}
            </Route>
            <Route
              match={() =>
                Store.router.section == "card" || Store.router.section == ""
              }
            >
              {Store.cards.current ? (
                Store.cards.hasPlayed ? (
                  <Thanks />
                ) : (
                  <Card />
                )
              ) : (
                <Invalid />
              )}
            </Route>
          </div>
        )}
      </ThemeProvider>
    );
  }
}

export default App;
