import { action, toJS, observable, computed, runInAction } from "mobx";

/**
 * i found various modules that do routing in React and then perhaps work with redux or mobx
 * too complex and buggy for whatever reason
 *
 * this is a very simple Router
 * the router state handles the reading and interpreting of the url on load
 *
 * it also intercepts clicks, determines if they are links within our current domain
 * and will push them into the History object, cancel the page navigation, parse the new url
 * and extract what it needs to call NxStore.loadState
 *
 * this state exposes several observable from the location values on load
 * it also exposes a projectId, which is parsed from the URL.
 *
 * to use this for routing, use the <Route> components
 * the Route component listens to the observables, path, hash, or runs a custom function match
 * if the route matches, the child components will be rendered
 *
 * @see /src/app/components/routing/Route.js
 */
export default class RouterState {
  constructor(root) {
    this.store = root;
  }

  store;

  @action
  async init() {
    this.query = this.parseQuery(window.location);
    this.pathname = window.location.pathname;
    const segments = this.pathname.split("/");
    segments.splice(0, 1);
    console.log(segments);
    console.log(toJS(this.query));
    this.segments = segments;
    this.hash = window.location.hash;
    window.addEventListener("popstate", e => {
      this.handlePopState(e);
    });
  }
  parseQuery(location) {
    let params = new URL(location).searchParams;
    let query = {};
    params.forEach((v, k) => {
      query[k] = v;
    });
    return query;
  }

  @observable query;

  @observable url;

  @observable pathname;

  @observable segments;

  @observable hash = "";

  @observable loadingState = false;

  @action
  setLoading(v: boolean) {
    this.loadingState = v;
  }

  @observable loadingProgress = 0;

  @computed get section() {
    return this.segments[0];
  }

  @computed get endpoint() {
    return this.segments[1];
  }

  loadingInterval = 30;

  async goToUrl(url) {
    try {
      runInAction(() => {
        this.loadingState = true;
      });
      const newLocation = this.parseLocation(url);
      this.setRouteState(newLocation);
      window.history.pushState(
        { pathname: this.pathname },
        this.pathname,
        this.pathname
      );
      runInAction(() => {
        this.loadingState = false;
      });
    } catch (e) {
      console.log(e.message, 2);
      runInAction(() => {
        this.loadingState = false;
      });
    }
  }

  handlePopState(e) {
    console.log(e, 1);
    this.goToUrl(document.location.pathname);
  }

  @action
  setRouteState(location) {
    this.query = this.parseLocation(location);
    this.pathname = location.pathname;
    this.segments = location.segments;
    this.hash = location.hash;
  }

  parseLocation(url) {
    const a = document.createElement("a");
    a.href = url;
    const segments = a.pathname.split("/").slice(1);
    console.log(segments);
    return {
      query: a.query,
      pathname: a.pathname,
      segments,
      hash: a.hash,
      url
    };
  }

  /* navigationHandler(event) {
    if (this.which(event) !== 1) return;
    if (event.metaKey || event.ctrlKey || event.shiftKey) return;
    if (event.defaultPrevented) return;

    // ensure target is a link
    let el = event.target;

    // el.nodeName for svg links are 'a' instead of 'A'
    while (el && el.nodeName.toUpperCase() !== 'A') {
      el = el.parentNode;
    }

    if (!el || el.nodeName.toUpperCase() !== 'A') return;

    // check if link is inside an svg
    // in this case, both href and target are always inside an object
    const svg = (typeof el.href === 'object') && el.href.constructor.name === 'SVGAnimatedString';

    // Ignore if tag has
    // 1. 'download' attribute
    // 2. rel='external' attribute
    if (el.hasAttribute('download') || el.getAttribute('rel') === 'external') return;

    // ensure non-hash for the same path

    // Check for mailto: in the href
    if (~el.href.indexOf('mailto:')) return;

    // check target
    // svg target is an object and its desired value is in .baseVal property
    if (svg ? el.target.baseVal : el.target) return;

    // x-origin
    // note: svg links that are not relative don't call click events (and skip watchLinks)
    // consequently, all svg links tested inside watchLinks are relative and in the same origin
    if (!svg && !this.sameOrigin(el.href)) return;

    // rebuild path
    // There aren't .pathname and .search properties in svg links, so we use href
    // Also, svg href is an object and its desired value is in .baseVal property
    const path = svg ? el.href.baseVal : (el.pathname + el.search + (el.hash || ''));

    this.parseLocation(path)

    event.preventDefault();
  }


  which(event) {
    event = event || window.event;
    return event.which === null ? event.button : event.which;
  }

  sameOrigin(href) {
    let origin = location.protocol + '//' + location.hostname;
    if (location.port) origin += ':' + location.port;

    return (href && (href.indexOf(origin) === 0));
  }

  */
}
