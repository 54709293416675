import React from "react";
import { ProgressBar } from "../UI/UI";
import "./Loading.css";
export default class Loading extends React.Component {
  render() {
    return (
      <div className={"loading"}>
        <ProgressBar type={"circular"} mode={"indeterminate"} />
      </div>
    );
  }
}
