import React, { Component } from "react";
import { observer } from "mobx-react";
import Store from "../../Store/Store";
import * as PropTypes from "prop-types";

export default
@observer
class Route extends Component {
  static propTypes = {
    section: PropTypes.string,
    match: PropTypes.func
  };

  constructor(props) {
    super(props);

    // default state
    console.log(props);
    if (!props.section && !props.match)
      throw new Error("routes need a section or match property to work");
  }

  /**
   * rendering the react UI
   * @returns {*}
   */
  render() {
    let routeDoesMatch = false;
    if (this.props.section) {
      if (this.props.section == Store.router.section) routeDoesMatch = true;
    } else if (this.props.match) {
      routeDoesMatch = this.props.match();
    }
    return <div>{routeDoesMatch ? this.props.children : null}</div>;
  }
}
