//import config from "./config";
import BaseModel from "../Store/Model/BaseModel";

/**
 * calls the API based on the configuration
 */
class Api {
  async loadSession() {
    return this.call("loadSession");
  }

  async createCard(cardModel: BaseModel) {
    return this.call("createCard", cardModel.toJS());
  }
  async uniqueEmail(email, id) {
    return this.call("uniqueEmail", { email: email, id: id });
  }
  async getCards() {
    return this.call("getCards");
  }
  async fetchCard(key) {
    return this.call("fetchCard", { key: key });
  }
  async saveCard(data) {
    return this.call("saveCard", data);
  }
  async savePlayed(key, amount) {
    return this.call("savePlayed", { key: key, amount: amount });
  }
  async setLang(lang) {
    return this.call("setlang", { lang: lang });
  }
  async getSession() {
    return this.call("getSession");
  }
  async login(pass) {
    return this.call("login", { password: pass });
  }
  async logout() {
    return this.call("logout");
  }
  async call(method, calldata) {
    console.log(`${window.CONFIG.apiUrl}${method}`);
    if (!calldata)
      return fetch(`${window.CONFIG.apiUrl}${method}`, {
        method: "GET",
        cache: "no-cache",
        mode: "cors",
        credentials: "include",
        headers: {
          Accept: "application/json"
        }
      }).then(response => response.json());
    else {
      console.log(JSON.stringify(calldata));
      let formdata = "";
      Object.keys(calldata).forEach(k => {
        if (formdata) formdata += "&";
        formdata += `${k}=${encodeURIComponent(calldata[k])}`;
      });
      return fetch(`${window.CONFIG.apiUrl}${method}`, {
        method: "POST", // or 'PUT'
        body: formdata, // data can be `string` or {object}!
        cache: "no-cache",
        mode: "cors",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }).then(response => response.json());
    }
  }

  /**
   * takes the high res image uploaded to the "2560/" directory and scales both the sharp and blurred versions
   * down to the selected size (representing height in pixels), and saves it into that size directory
   *
   * @param {string} sizeDir the size directory to scale the image to. example: "640/". the numeric value is the destination height of the image
   * @param {string} projectId the project id, used to find the highest res (originally uploaded) file as the source file
   * @memberof Api
   */
  async scaleImages(sizeDir: string, projectId: string) {
    return this.call("scaleImages", { dir: sizeDir, projectId: projectId });
  }
}
const api = new Api();
export default api;
