import React, { Component } from "react";
import { observer } from "mobx-react";
import * as PropTypes from "prop-types";
import Api from "../../Api/Api";
import Store from "../../Store/Store";
import {
  Dialog,
  Card,
  Input,
  Button,
  CardText,
  CardTitle,
  TooltipButton,
  Switch
} from "../UI/UI";

import "./NewCardDialog.css";

/**
 * NewCardDialog
 *
 */
@observer
export default class NewCardDialog extends Component {
  // prop types
  static propTypes = {};
  // default properties
  static defaultProps = {};
  actions = [
    {
      label: Store.lang.txt["cancel"],
      onClick: () => {
        Store.cards.cancelCreate();
      },
      primary: true,
      disabled: Store.cards.saving
    },
    {
      label: Store.lang.txt["save"],
      onClick: async () => {
        let success = await Store.cards.saveCard();
        if (success) {
          Store.cards.clearModel();
        }
      },
      disabled: Store.cards.saving,
      primary: true
    }
  ];
  render() {
    if (!Store.cards.model) return null;
    return (
      <Dialog
        active={Store.cards.isEditing}
        title={Store.lang.txt["create_card_title"]}
        actions={this.actions}
        onEscKeyDown={() => Store.cards.cancelCreate()}
        onOverlayClick={() => Store.cards.cancelCreate()}
        className={"create-dialog"}
      >
        <Input
          label={Store.lang.txt.email}
          error={
            Store.cards.model.validated
              ? Store.lang.txt[Store.cards.model.email.error]
              : null
          }
          value={Store.cards.model.email.value}
          onChange={v => {
            Store.cards.model.email.setValue(v);
          }}
        />
        {Store.lang.txt["lang"]}: en{" "}
        <Switch
          checked={Store.cards.model.lang.value === "fr"}
          onChange={v => {
            console.log(v);
            Store.cards.model.lang.setValue(v ? "fr" : "en");
          }}
        />{" "}
        fr
        {Store.cards.model.link_key.value ? (
          <p className={"link"}>
            {window.CONFIG.linkUrl + Store.cards.model.link_key.value}
          </p>
        ) : null}
        {Store.cards.saveError ? (
          <p class="form-error">{Store.lang.txt[Store.cards.saveError]}</p>
        ) : null}
      </Dialog>
    );
  }
}
