import React, { Component } from "react";
import { observer } from "mobx-react";
import $ from "jquery";
import Store from "../../../Store/Store";
import $15 from "../../../assets/15.png";
import $20 from "../../../assets/20.png";
import $25 from "../../../assets/25.png";
import $50 from "../../../assets/50.png";

export default
@observer
class Card extends Component {
  UNSAFE_componentWillMount() {
    for (let i = this.amount.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [this.amount[i], this.amount[j]] = [this.amount[j], this.amount[i]];
    }
  }
  componentDidMount() {
    var music = new Audio("assets/music/Frosty-the-Snowman.mp3");

    //Fait jouer la musique en cliquant sur l'icone
    $(".sound").click(function() {
      if (music.paused && music.currentTime >= 0 && !music.ended) {
        music.play();
        $(this).addClass("on");
      } else {
        music.pause();
        $(this).removeClass("on");
      }
    });
  }
  amount = [50, 25, 25, 25, 20, 20, 20, 20, 15, 15, 15, 15];
  icons = [
    "/img/icon-gift.png",
    "/img/icon-cane.png",
    "/img/icon-globe.png",
    "/img/icon-snowman.png",
    "/img/icon-gingerbread.png",
    "/img/icon-stocking.png",
    "/img/icon-reindeer.png",
    "/img/icon-holly.png",
    "/img/icon-sleigh.png",
    "/img/icon-snowflake.png",
    "/img/icon-ball.png",
    "/img/icon-hat.png"
  ];
  bg = [
    "bg-red",
    "bg-silver",
    "bg-green",
    "bg-gold",
    "bg-green",
    "bg-gold",
    "bg-red",
    "bg-silver",
    "bg-silver",
    "bg-red",
    "bg-gold",
    "bg-green"
  ];
  amountImages = { $15: $15, $20: $20, $25: $25, $50: $50 };
  render() {
    return (
      <div>
        <div className="row d-flex justify-content-center">
          <div className="col-12 col-md-9 text-center my-4">
            <img id="logo" src="/img/liv-logo.png" />
          </div>
          {/* Logo */}
        </div>
        <div id="game-header" className="row justify-content-center">
          <div className="col-12 col-md-9 text-center">
            <p
              dangerouslySetInnerHTML={{
                __html: Store.lang.txt["game_text_1"]
              }}
            />
            <p>{Store.lang.txt["game_text_2"]}</p>
          </div>
        </div>
        {/* Header */}
        <div id="card-grid" className="row justify-content-center mt-3">
          <div className="col-12 col-md-9">
            <div className="row">
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(i => (
                <div
                  key={"card" + i}
                  className="col-4 col-md-3 card-wrapper mb-4"
                  onClick={() => {
                    if (Store.cards.selected < 0)
                      Store.cards.selectCard(i, this.amount[i]);
                  }}
                >
                  <div
                    className={`flip-card${
                      Store.cards.selected === i ? " is-flipped" : ""
                    }`}
                    data-index={i}
                  >
                    <div className={`card-face card-face-front ${this.bg[i]}`}>
                      <img className="img-fluid" src={this.icons[i]} />
                    </div>
                    <div className="card-face card-face-back">
                      <img
                        className="img-fluid"
                        src={
                          Store.cards.selected === i
                            ? this.amountImages["$" + this.amount[i]]
                            : "/img/amount_placeholder.jpg"
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* Row */}
          </div>
          {/* Col-10 */}
        </div>
      </div>
    );
  }
}
