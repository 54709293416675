import React, { Component } from "react";
import { observer } from "mobx-react";
import Store from "../../../Store/Store";
export default
@observer
class Thanks extends Component {
  render() {
    return (
      <div>
        <div id="header" className="row">
          <div className="col-12 text-center mt-4">
            <img id="logo" src="/img/liv-logo.png" />
          </div>
        </div>
        {/* Header */}
        <div id="content" className="row justify-content-center mt-5">
          <div className="col-12 col-md-9">
            <div className="row justify-content-center">
              <div
                id="amount"
                className="col-6 col-md-3 py-5 bg-orange shadow text-center text-white display-3"
              >
                <span className="my-1">
                  <strong>
                    {Store.cards.current.amount}
                    <small>$</small>
                  </strong>
                </span>
              </div>
            </div>
            <div className="row justify-content-center mt-1">
              <div className="col-6 col-md-3 py-4 bg-light-grey text-center shadow">
                <p className="m-0">
                  <strong>{Store.lang.txt["donation"]}</strong>
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-10 text-center mt-5">
            <p
              dangerouslySetInnerHTML={{
                __html: Store.lang.txt["working_2020"]
              }}
            ></p>
            <h2 className="display-5 liv-orange">
              {Store.lang.txt["happy_holidays"]}
            </h2>
          </div>
        </div>
      </div>
    );
  }
}
