import React, { Component } from "react";
import { observer } from "mobx-react";
import Store from "../../Store/Store";
import "./Login.css";
import { Button, Input, Card, CardText, CardTitle, Switch } from "../UI/UI";
@observer
export default class Login extends Component {
  state = {
    password: ""
  };
  render() {
    return (
      <div className={"admin"}>
        <div id="header" className="row">
          <div className="col-12 text-center mt-4">
            <img id="logo" src="/img/liv-logo.png" />
          </div>
          <div className="col-12 text-right mt-4 lang-selection">
            {Store.lang.txt["lang"]}: <strong>en </strong>
            <Switch
              checked={Store.lang.current === "fr"}
              onChange={v => {
                console.log(v);
                Store.lang.setLanguage(v ? "fr" : "en");
                Store.setAdminLang(v ? "fr" : "en");
              }}
            />{" "}
            <strong>fr</strong>
          </div>
        </div>
        <div className="login">
          <Card>
            <CardTitle>{Store.lang.txt.login_title}</CardTitle>
            <CardText>
              <Input
                label={Store.lang.txt.admin_password}
                value={Store.password}
                onChange={v => this.setState({ password: v })}
                type={"password"}
              />
              <Button
                label={Store.lang.txt.login}
                onClick={() => Store.login(this.state.password)}
                primary
                raised
              />
            </CardText>
          </Card>
        </div>
      </div>
    );
  }
}
