import { observable, action } from "mobx";
export default class LangState {
  constructor(rootState) {}

  state;

  @observable lang = "en";
  @observable txt = {};

  dictionaries = {
    en: {},
    fr: {}
  };

  @observable
  current: string = "en";

  @action
  setLanguage(langKey: string) {
    console.log("setting language to " + langKey);
    this.current = langKey;
    this.txt = this.dictionaries[langKey];
  }
  /**
   * though there is no need for this function to be async,
   * it is the standard in these "subState" classes to have
   * an init function which returns a promise
   *
   * @param {string} sessionLang
   * @returns {Promise<boolean>}
   * @memberof LangState
   */
  async init(sessionLang: string) {
    this.dictionaries.en = await fetch("/lang/en.json").then(r => r.json());
    this.dictionaries.fr = await fetch("/lang/fr.json").then(r => r.json());
    console.log(sessionLang);
    this.setLanguage(sessionLang);
    return true;
  }

  /**
   * translation interpolation function. treats the translations as template strings.
   * - produces translations whose form changes in the plural.
   * - additionally the function will add up to 3 numerical values
   * - text can be formateted as follows
   *     __ __ for <em>
   *     * * for <strong>
   *     [ ] for links

   * The translation requries that the value for key 
   * have two forms in the 
   *
   * @param {string} key the tranlation key
   * @param {number|null} quant a quantity for translations that have singular or plural forms, a value of one produces singular.
   * @param {*} var1 interpolated value1
   * @param {*} var2 interpolated value2
   * @param {*} var3 interpolated value3
   * @param {string} link
   * @returns {string}
   */
  text(
    key: string,
    quant: number = null,
    var1 = "",
    var2 = "",
    var3 = "",
    link = null
  ) {
    // console.log('the key is : %s',key,1)
    if (this.txt[`${key}_1`] !== undefined) {
      if (quant === 1) {
        key = `${key}_1`;
      } else {
        key = `${key}_many`;
      }
    }

    if ((quant || quant === 0) && !var1) var1 = quant;
    /**
     *
     *   the template regexes use
     *     __ __ for <em>
     *     * * for <strong>
     *     [ ] for links
     */

    if (isNaN(var1) && this.txt[var1]) var1 = this.txt[var1];
    try {
      // formatted string is backticked and eval'd to render the ${var1} in the string
      return eval(
        "`" +
          this.txt[key]
            .replace(/(.*)\*(.+)\*(.*)/g, "$1<strong>$2</strong>$3")
            .replace(/([^*]*)\*([^*]+)\*(.*)/, "$1<strong>$2</strong>$3") // this works
            .replace(/(.*)__(.+)__(.*)/, "$1<em>$2</em>$3")
            .replace(/(.*)__(.+)__(.*)/, "$1<em>$2</em>$3")
            .replace(/(.*)__(.+)__(.*)/, "$1<em>$2</em>$3")
            .replace(/(.*)__(.+)__(.*)/, "$1<em>$2</em>$3")
            .replace(/(.*)__(.+)__(.*)/, "$1<em>$2</em>$3") // there is surely a smarter way to do this but for now this allows for multiple <em>s, etc
            .replace(/(.*)\[(.+)\](.*)/, `$1<a href="${link}">$2</a>$3`) +
          "`"
      )
        .replace(/\n/, "<br/>")
        .replace(/\n/, "<br/>")
        .replace(/\n/, "<br/>");
    } catch (e) {
      console.log("error: " + e.message + " key is:" + key, 2);
    }
  }
}
