import { action, observable } from "mobx";
import BaseModel from "../Model/BaseModel";
import Field, { FieldType } from "../Model/Field";
import { uniqueid } from "uniqueid";
import Api from "../../Api/Api";
/**
 * CardModel
 */
export default class CardModel extends BaseModel {
  @observable id = new Field(this, "id", {
    primary: true,
    type: FieldType.int
  });

  @observable email = new Field(this, "email", {
    required: true,
    type: FieldType.string,
    validation: value => {
      if (
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
          value
        )
      ) {
        return null;
      }
      return "invalid_email";
    },
    unique: async v => {
      //console.log(this);
      let response = await Api.uniqueEmail(v, this.id.value);
      return response.unique ? null : "email_exists";
    }
  });

  @observable lang = new Field(this, "lang", {
    default: "en",
    type: FieldType.string
  });

  @observable link_key = new Field(this, "link_key", {
    type: FieldType.string
  });
}
