import React, { Component } from "react";
import { observer } from "mobx-react";
import Store from "../../Store/Store";
import { Card, CardText, IconButton } from "../UI/UI";
import "./CardGrid.css";
/**
 * CardGrid
 *
 */
@observer
export default class CardGrid extends Component {
  render() {
    return (
      <Card raised className={"card-grid"}>
        <CardText>
          <table>
            <tbody>
              <tr>
                <th>{Store.lang.txt["email"]}</th>
                <th className={"link-col"}>{Store.lang.txt["link"]}</th>
                <th>{Store.lang.txt["lang"]}</th>
                <th>{Store.lang.txt["date_played"]}</th>
                <th>{Store.lang.txt["amount"]}</th>
                <th>{Store.lang.txt["edit"]}</th>
              </tr>
              {Store.cards.list.map(row => (
                <tr key={`row${row.id}`}>
                  <td>{row.email}</td>
                  <td>
                    {window.CONFIG.linkUrl}
                    {row.link_key}
                  </td>
                  <td>{row.lang}</td>
                  <td>{row.date_played ? row.date_played : ""}</td>
                  <td>{row.amount ? "$" + row.amount : ""}</td>
                  <td>
                    {
                      <IconButton
                        icon={"edit"}
                        primary
                        disabled={Store.cards.isEditing}
                        onClick={() => {
                          Store.cards.editCard(row);
                        }}
                      />
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </CardText>
      </Card>
    );
  }
}
