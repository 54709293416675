import { observable, action, runInAction, toJS, computed } from "mobx";
import Api from "../../Api/Api";
import { State } from "../../Store/Store";
import CardModel from "../models/CardModel";
import BaseModel from "../Model/BaseModel";

export default class CardState {
  @observable saving = false;
  @observable saveError = null;
  @observable list = [];
  @observable selected = -1;
  @computed get hasPlayed() {
    return !!this.current.date_played;
  }

  @observable current = {};
  @observable model: BaseModel = null;
  @computed get isEditing() {
    return !!this.model;
  }

  store: State;

  constructor(rootState) {
    this.store = rootState;
  }

  async init() {
    if (this.store.router.section === "admin") {
      await this.getList();
    } else {
      await this.fetchCard();
    }
    return true;
  }

  @action async getList() {
    let response = await Api.getCards();
    runInAction(() => {
      this.list = response.cards;
    });
  }
  @action async fetchCard() {
    let key = this.store.router.query["key"];
    console.log(key);
    let response = await Api.fetchCard(key);
    runInAction(() => {
      this.current = response.card;
      if (this.current && this.current.lang)
        this.store.lang.setLanguage(this.current.lang);
    });
  }

  @action selectCard(index, dollars) {
    this.selected = index;
    setTimeout(() => {
      this.savePlayed(dollars);
    }, 2000);
  }

  @action async savePlayed(dollars) {
    let response = await Api.savePlayed(this.current.link_key, dollars);
    runInAction(() => {
      this.current = response.card;
    });
  }

  @action createCard() {
    this.model = new CardModel();
    this.model.init();
  }
  @action editCard(data) {
    this.model = new CardModel();
    this.model.init(data);
  }
  @action cancelCreate() {
    this.model = null;
  }
  @action async saveCard() {
    this.saving = true;
    this.saveError = null;
    console.dir(this.model.toJS());
    let isValid = await this.model.validate();
    if (!isValid) {
      runInAction(() => {
        this.saving = false;
      });
      return false;
    }

    let response = await Api.saveCard(this.model.toJS());
    if (response.success) {
      runInAction(() => {
        this.saving = false;
        this.saveError = null;
      });
      await this.getList();
      return true;
    } else {
      runInAction(() => {
        this.saving = false;
        this.saveError = "save_error";
      });
      return false;
    }
  }
  @action clearModel() {
    this.model = null;
  }
}
