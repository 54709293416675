import React, { Component } from "react";
import CardGrid from "../CardGrid/CardGrid";
import { observer } from "mobx-react";
import "./Admin.css";
import { Button, Dialog, IconButton, Switch } from "../UI/UI";
import Store from "../../Store/Store";
import NewCardDialog from "../dialogs/NewCardDialog";
export default
@observer
class Admin extends Component {
  render() {
    return (
      <div className={"admin"}>
        <div id="header" className="row">
          <div className="col-12 text-center mt-4">
            <img id="logo" src="/img/liv-logo.png" />
          </div>
        </div>
        <div className={"row"}>
          <div className="col-12 text-right mt-4 lang-selection">
            <Button
              label={Store.lang.txt.logout}
              onClick={() => {
                Store.logout();
              }}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
            {Store.lang.txt["lang"]}: <strong>en </strong>
            <Switch
              checked={Store.lang.current === "fr"}
              onChange={v => {
                Store.lang.setLanguage(v ? "fr" : "en");
                Store.setAdminLang(v ? "fr" : "en");
              }}
            />{" "}
            <strong>fr</strong>
          </div>
        </div>
        <div className={"admin"}>
          <CardGrid />
          <div className={"controls"}>
            <Button
              primary
              raised
              label={Store.lang.txt["new_card"]}
              onClick={() => {
                Store.cards.createCard();
              }}
            />
            <NewCardDialog />
          </div>
        </div>
      </div>
    );
  }
}
